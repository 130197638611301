body {
    color: #313131;
    background-color: #313131;
}

.dropdown-menu {
    overflow-y: scroll;
    height: 400px;
}

.App {
  text-align: center;
}

.alert {
    color: white;
}

.alert-danger {
    background-color: #FF5F5F;
}

.alert-success {
    background-color: green;
}

.alert button {
    width: 10px;
    height: 10px;
}

.alert p {
    font-size: 0.85rem;
    margin-bottom: 0;
}

.alert .btn-close {
    color: white;
}

.dashboard-notification {
    border-radius: 1rem;
}

.good {
    background-color: #00BDAA;
}

.attention {
    background-color: #EAD200;
}

.severe {
    background-color: #FF5F5F;
}

.dashboard-notification .btn {
    color: white;
    border-width: 0px;
    text-decoration: underline;
}

.dashboard-notification .btn:hover, 
.dashboard-notification .btn:focus, 
.dashboard-notification .btn:active {
    color: white;
}

.bg-green {
  background-color: #00BDAA;
}

.bg-dark {
    background-color: black;
}

.big-header {
    font-size: 4rem;
    font-weight: 400;
}

.big {
    font-size: 2rem;
    font-weight: 700;
}

.small {
    font-weight: 400;
    font-size: 1rem;
}

.hint {
    font-size: 0.75rem;
    font-weight: 700;
}

.small-title {
    font-size: 0.75rem;
    font-weight: 700;
    color: gray;
}

.alert-red {
    width: 100%;
    height: 4px;
    background-color: #FF5F5F;
}

.alert-green {
    width: 100%;
    height: 4px;
    background-color: #00BDAA;
}

.white-text {
    color: white;
}